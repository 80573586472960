export const pricehome = [
  {
    title: 'SENCILLO',
    from: '',
    amount: '0',
    monthly: '',
    annual: ' ',
    save: ' ',
    list1: 'Registra kilos manualmente',
    list2: 'Control de inventarios ',
    list31: 'Control de caja chica',
    list4: 'Solo 2 proveedores ',
    list51: 'Control de clientes',
    list61: ' Usuarios ilimitados',
    list7: 'Impresión de tickets',
    list8: 'Siempre gratis, "ideal para empezar"',
    list9: '',
    btn: 'Gratis',
    extrauser: ''

  }, {
    title: 'SENCILLO',
    from: 'Desde',
    amount: '949',
    monthly: 'Mensual',
    annual: '',
    save: '  "Ahorra 30%"',
    list1: 'Control sobre dos o más básculas de plataforma',
    list2: ' Control de inventarios',
    list3: 'Control de caja chica ',
    list4: 'Control de proveedores',
    list5: 'Control de clientes',
    list6: 'Usuarios ilimitados',
    list7: 'Impresión de tickets ',
    list8: 'Plan mensual, "El más económico"',
    list9: 'Control sobre dos o más básculas de plataforma',
    btn: 'Comprar',
    extrauser: 'Costo por usuario extra $99 mxn al mes'

  },
  {
    title: 'CAMIONERO',
    from: 'Desde',
    amount: '1,390',
    monthly: 'Mensual',
    annual: '',
    save: '  "Ahorra 30%',
    list1: 'Pesaje camionero',
    list2: 'Control sobre una báscula de camión y de una o más básculsa de plataforma ',
    list3: ' Control de inventarios ',
    list4: 'Control de caja chica',
    list5: ' Control de proveedores',
    list6: 'Control de clientes',
    list7: 'Usuarios ilimitados',
    list8: ' Impresión de tickets',
    list9: 'Plan mensual, "El más económico"',
    btn: 'Comprar',
    extrauser: 'Costo por usuario extra $99 mxn al mes'

  }

]
