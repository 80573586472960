import Image from 'next/image'
import testi from '@/styles/testimonials/index.module.scss'

export const Start = () => {
  return (
    <Image
      src='/img/testimonios/icon.svg'
      className={testi.icon_img1}
      alt='Icon 1 '
      width={24}
      height={24}
    />
  )
}
