import React, { useEffect } from 'react'

// componets
import Home from '@/components/organisms/Home'
import { HomeProvider } from '@/context/guest/Home/HomeProvider'
import Cookies from 'js-cookie'
import { TOKEN, RECOVERY_ADVERTISE } from '@/utils/constants'
import { encrypt } from '@/utils'
import { sweetError } from '@/utils/sweetalert2'
// import { Login } from '@/components/organisms/Login'
import axios from 'axios'

const Index = ({ tokenGuest }) => {
// export default function index ({ tokenGuest }) {
  useEffect(() => {
    if (!tokenGuest.error && tokenGuest.token) {
      Cookies.set(TOKEN, encrypt(tokenGuest.token))
      Cookies.set(RECOVERY_ADVERTISE, true)
    } else {
      sweetError('', tokenGuest.message)
    }
  }, [tokenGuest])

  return (
    <HomeProvider>
      <Home />
    </HomeProvider>
  )
}

export async function getServerSideProps () {
  try {
    const baseURL = process.env.API_PASSPORT_URL
    const formData = new FormData()
    formData.append('grant_type', 'client_credentials')
    formData.append('client_id', process.env.CLIENT_ID)
    formData.append('client_secret', process.env.CLIENT_SECRET)
    const response = await axios.post(`${baseURL}/oauth/token`, formData)
    const { access_token: accessToken } = response.data
    return {
      props: {
        tokenGuest: {
          error: false,
          token: accessToken
        }
      }
    }
  } catch (error) {
    return {
      props: {
        tokenGuest: {
          error: true,
          message: error.message
        }
      }
    }
  }
}

export default Index
