import indexStyle from './CardVideoHome.module.scss'
import Image from 'next/image'

export const CardVideoHome = () => {
  return (
    <div className={indexStyle.second_row2}>
      <div className={indexStyle.card_video}>
        <div className={indexStyle.text}>
          <h2 className={indexStyle.titlevideo}>¿Por qué usar <span className={indexStyle.title}>SOFTBASYS?</span> </h2>
          <p className={indexStyle.descriptionvideo}><span className={indexStyle.descriptionbold}>BASYS</span> es el sistema de punto de venta más completo <span className={indexStyle.descriptionbold}>especializado</span>  para recicladoras.</p>
          <div className={indexStyle.right}>
            <Image
              src='/img/home/medalla.png'
              alt='Picture of the author '
              width={30}
              height={30}
            />
            <p className={indexStyle.descriimg}>Garantizado por recicladores satisfechos</p>
          </div>
        </div>
        <div className={indexStyle.video}>
          <iframe className={indexStyle.videoTam} width='775' height='442' src='https://www.youtube.com/embed/bDrIeudc214?autoplay=1' title='YouTube video player' frameBorder='0' allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' allowFullScreen />
        </div>
      </div>
    </div>
  )
}
