import { Starts } from '@/components/moleculas/Starts/Starts'
import testi from '@/styles/testimonials/index.module.scss'
import Image from 'next/image'

export const TestimonialCard = ({ title, subtitle, description, image: { name: imgName, url: imgUrl } }) => {
  return (
    <div className={testi.card}>
      <div className={testi.column}>
        <div className={testi.horizontal}>
          <div className={testi.horizontalImage}>

            <Image
              src={imgUrl}
              alt={imgName}
              className={testi.image}
              width={109}
              height={109}
            />
          </div>
          <div className={testi.verticalText}>
            <p className={testi.title}>{title}</p>
            <p className={testi.subtitle}>{subtitle}</p>
            <Starts />
          </div>

        </div>
      </div>
      <div className={testi.column}>
        <p className={testi.textcol} style={{ color: 'black' }}>{description}</p>
      </div>
    </div>
  )
}
