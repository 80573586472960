import { Start } from '@/components/atoms/Start/Start'
import testi from '@/styles/testimonials/index.module.scss'
export const Starts = ({ numberStars = 5 }) => {
  const elements = Array(numberStars).fill().map((_, i) => (
    <Start key={i} />
  ))

  return (
    <>
      <div className={testi.icon_list}>
        {elements}
      </div>
    </>
  )
}
