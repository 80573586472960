// import { Inter } from 'next/font/google'
import Head from 'next/head'
import indexStyle from '@/styles/index.module.scss'
import { Menu } from '@/components/organisms/Menu/Menu'
import { Footer } from '@/components/organisms/Footer/Footer'
import Image from 'next/image'
import { NavLink } from '@/components/atoms/NavLink/NavLink'
import { TestimonialCard } from '@/components/organisms/TestimonialCard/TestimonialCard'
import { testimonialshome } from '@/data/dummy/testimonialshome'
import { pricehome } from '@/data/dummy/pricehome'
import { Contact } from '@/components/organisms/Contact/Contact'
// import PriceCardHome from '@/components/moleculas/PriceCardHome/PriceCardHome'
// import { Advertisement } from '@/components/organisms/Advertisement/Advertisement'
import { HomeCookies } from '@/components/organisms/HomeCookies/HomeCookies'
import { BuyPlans } from '@/components/organisms/BuyPlans/BuyPlans'
import { CardVideoHome } from '@/components/organisms/CardVideoHome/CardVideoHome'
import { HomeContext } from '@/context/guest/Home'
import { useContext } from 'react'
import CardMembership from '../CardMembership/CardMembership'
import PriceCardHome from '@/components/moleculas/PriceCardHome/PriceCardHome'

const Home = () => {
  const { subscriptions } = useContext(HomeContext)
  // console.log('subscriptions', subscriptions)
  function chunk (arr, size) {
    return arr.reduce((result, item, index) => {
      const chunkIndex = Math.floor(index / size)
      if (!result[chunkIndex]) {
        result[chunkIndex] = []
      }
      result[chunkIndex].push(item)
      return result
    }, [])
  };
  const testimonialGrups = chunk(testimonialshome, 3)
  const pricehomeGrups = chunk(pricehome, 3)
  const elementos = [
    'Registra facilmente las entradas y salidas de materal',
    'Controla los gastos de caja chica',
    'Realiza el corte en segundos y recibelo en tu celular',
    'Precios de compra/venta controlados',
    'Inventarios exactos siempre',
    'Toma la lectura de los pesos desde la bascula conectandola a la pc',
    'Entrega ticket formal a tus clientes',
    'Genera estados de cuentas',
    'Pasaje de plataformas y basculas camioneras (bruto, tara neto)'

  ]
  return (
    <>
      <Head>
        <title> Basys </title>
      </Head>
      <Menu />
      <div className={indexStyle.body_container}>

        <div className={indexStyle.background_image}>
          <div className={indexStyle.content_container}>
            <h1>Sistema de puntos de venta para chatarreras y básculas</h1>
            <h3>Aumenta tus ganancias y controla tu inventario</h3>
            <div className={indexStyle.divBtn}>
              <NavLink href='/login' name='Iniciar sesión' className={indexStyle.button_1} />
              <NavLink href='/register' name='Prueba gratis' className={indexStyle.button_2} />
            </div>
          </div>
        </div>
        <div className={indexStyle.second_row}>
          <div className={indexStyle.column}>
            <h1 className={indexStyle.title1}>Automatiza y gana</h1>
            <ul>
              {elementos.map((elemento, index) => (
                <li key={index}>{elemento}</li>
              ))}
            </ul>
            <div className={indexStyle.row}>
              <div className={indexStyle.card_container}>
                <div className={indexStyle.card_container2}>
                  <div className={indexStyle.card_column}>
                    <div className={indexStyle.card}>
                      <div className={indexStyle.icon_container}>
                        <Image
                          src='/img/home/evaluacion.svg'
                          className={indexStyle.evaluation}
                          alt='Picture of the author '
                          width={96}
                          height={96}
                        />
                      </div>
                    </div>
                    <div className={indexStyle.description}>
                      <p className={indexStyle.location2}>Controla la merma</p>
                      <p className={indexStyle.location2}>Elimina los pesos por factores de humedad, clasificación de productos, volatilidad, etc.</p>
                    </div>
                  </div>
                  <div className={indexStyle.card_column}>
                    <div className={indexStyle.card}>
                      <div className={indexStyle.icon_container}>
                        <Image
                          src='/img/home/combined_shape.svg'
                          alt='Picture of the author '
                          className={indexStyle.shape}
                          width={96}
                          height={96}
                        />
                      </div>
                    </div>
                    <div className={indexStyle.description}>
                      <p className={indexStyle.location2}>Soporte técnico</p>
                      <p className={indexStyle.location2}>Contamos con base de conocimientos, tutoriales y área específica para brindar apoyo</p>
                    </div>
                  </div>

                </div>
                <div className={indexStyle.column2}>
                  <Image
                    src='/img/home/mocup.png'
                    alt='Picture of the author '
                    width={240}
                    height={399}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={indexStyle.column}>
            <Image
              src='/img/home/mocup.png'
              alt='Picture of the author '
              className={indexStyle.img_tel}
              width={798}
              height={1329}
            />
          </div>
          {/* <div className={indexStyle.containerCe}>
            <div className={indexStyle.containerCel}>
              <div className={indexStyle.card}>
                <Image
                  src='/img/home/Combined2.svg'
                  alt='Picture of the author '
                  width={41}
                  height={38}
                />
              </div>
              <div className={indexStyle.content}>
                <h2>Flexible y escalable</h2>
                <p>¡Si tu empresa crece nosotros crecemos contigo! Basys ofrece módulos personalizados para tu negocio</p>
              </div>

            </div>
            <div className={indexStyle.containerCel}>
              <div className={indexStyle.card}>
                <Image
                  src='/img/home/Combined.svg'
                  alt='Picture of the author '
                  width={41}
                  height={38}
                />
              </div>
              <div className={indexStyle.content}>
                <h2>Flexible y escalable</h2>
                <p>¡Si tu empresa crece nosotros crecemos contigo! Basys ofrece módulos personalizados para tu negocio</p>
              </div>
            </div>
          </div> */}
        </div>
        <div className={indexStyle.second_row}>
          <CardVideoHome />

        </div>
        <div className={indexStyle.container_grup}>

          <BuyPlans />
          <div className={indexStyle.container_tab}>
            <div className={indexStyle.column_tab}>
              <p className={indexStyle.title_tab}>Especializado para recicladoras, chatarreras y básculas</p>
              <p className={indexStyle.description_tab}>Software para gestión de residuos y básculas, relacionadas a la gestión del almacenamiento, transporte y disposición de los residuos generados.</p>
              <div className={indexStyle.button_tab}>
                <NavLink href='' name='Iniciar sesión' className={indexStyle.button1} />
                <NavLink href='' name='Registrarme' className={indexStyle.button2} />
              </div>
            </div>
            <div className={indexStyle.column_tab} />
          </div>
          <div className={indexStyle.container_baby}>
            <Image
              src='/img/logo.png'
              alt='Picture of the author '
              width={302}
              height={85}
            />
            <p className={indexStyle.titlebabys}>Softbasys</p>
            <p className={indexStyle.descriptionbaby}>Deja de usar sistemas que no se ajustan a los procesos de compra/venta de materiales y solo hacen más complicada tu administración.</p>
          </div>
          <div className={indexStyle.container_baby}>
            <div className={indexStyle.card_container}>
              <div className={indexStyle.card}>
                <Image
                  src='/img/home/group.svg'
                  alt='Picture of the author '
                  className={indexStyle.cardimg}
                  width={130}
                  height={130}
                />
              </div>
              <div className={indexStyle.card}>
                <Image
                  src='/img/home/documents.svg'
                  alt='Picture of the author '
                  className={indexStyle.cardimg}
                  width={130}
                  height={130}
                />
              </div>
              <div className={indexStyle.card}>
                <Image
                  src='/img/home/printer.svg'
                  alt='Picture of the author '
                  className={indexStyle.cardimg}
                  width={130}
                  height={130}
                />
              </div>
              <div className={indexStyle.card}>
                <Image
                  src='/img/home/report.svg'
                  alt='Picture of the author '
                  className={indexStyle.cardimg}
                  width={130}
                  height={130}
                />
              </div>
            </div>
            <div className={indexStyle.description_container}>
              <div className={indexStyle.description}>
                <p className={indexStyle.card_description}>Evita errores por manipulación humana</p>
              </div>
              <div className={indexStyle.description}>
                <p className={indexStyle.card_description}>Hojas de embarque con folio consecutivo</p>
              </div>
              <div className={indexStyle.description}>
                <p className={indexStyle.card_description}>Reimpresión de hojas de embarque</p>
              </div>
              <div className={indexStyle.description}>
                <p className={indexStyle.card_description}>Reportes de embarque</p>
              </div>
            </div>

          </div>
        </div>

        <div className={indexStyle.container_testi}>
          <p className={indexStyle.titlebabys}>Testimonios</p>
          {
            testimonialGrups.map((group, index) => (
              <div key={index} className={indexStyle.body_section}>
                {
                    group.map((testimonialshome, index) => (
                      <TestimonialCard key={index} {...testimonialshome} />
                    ))
                }
              </div>
            ))
          }
          <div className={indexStyle.container_plans} id='plansAndSubs'>
            <p className={indexStyle.titlebabys}>Planes y Suscripciones</p>
            <p className={indexStyle.descriptionbaby}>Consulta los precios que ofrecemos, escoge el plan que más se adecúe a tus necesidades.</p>
            <div className={indexStyle.membership_section}>
              {subscriptions.map((membership, index) => <PriceCardHome membership={membership} key={index} />)}
            </div>
            {/* {
              pricehomeGrups.map((group, index) => (
                <div key={index} className={indexStyle.body_section}>
                  {
                    group.map((pricehome, index) => (
                      <PriceCardHome key={index} {...pricehome} />
                    ))
                  }
                </div>)
              )
            } */}
          </div>
          <div className={indexStyle.container_contact}>
            <Contact />
          </div>
          {/* <Advertisement /> */}
          <HomeCookies />
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Home
