import indexStyle from './BuyPlans.module.scss'

import { NavLink } from '@/components/atoms/NavLink/NavLink'

export const BuyPlans = () => {
  return (
    <div className={indexStyle.second_row2}>

      <div className={indexStyle.card_buys}>
        <p className={indexStyle.title}>Compara planes</p>
        <p className={indexStyle.description}>Analiza cuál es tu mejor opción, si tienes dudas contáctanos para una asesoría personalizada.</p>
        <NavLink href='#plansAndSubs' name='Ver tabla' className={indexStyle.button_buys} />
      </div>
    </div>

  )
}
