export const testimonialshome = [
  {
    image: {
      name: 'Diego Medrano',
      url: '/img/withoutPictureProfile.jpg'
    },
    title: 'Diego Medrano',
    subtitle: 'Reciclacentro',
    description: 'Desde que encontramos este software, hemos visto una mejora significativa en la gestión de nuestro inventario. Antes era un caos llevar el control de materiales reciclados y el flujo de entrada y salida de productos. Ahora, todo está automatizado y podemos generar reportes precisos al instante. Además, el soporte técnico ha sido excelente, siempre disponibles para ayudarnos a sacar el máximo provecho de la herramienta.'
  },
  {
    image: {
      name: 'Juan Hernández',
      url: '/img/withoutPictureProfile.jpg'
    },
    title: 'Juan Hernández',
    subtitle: 'La Peña',
    description: 'Tenemos una mejor administración de nuestros tiempos así como un mejor control de inventarios y del personal, además contamos con los reportes en vistas diferentes que nos ayudan a gestionar mejor nuestra empresa.'
  },
  {
    image: {
      name: 'Bryan Delgado',
      url: '/img/withoutPictureProfile.jpg'
    },
    title: 'Bryan Delgado',
    subtitle: 'Recicladora Trojes',
    description: 'Lo que más me gusta de este software es la precisión con la que podemos llevar el control de nuestro inventario, control de clientes, control de tickets, control de adelantos y sobre todo los ingresos y gastos fuera de los productos. Nos ha permitido reducir errores en los registros y mejorar nuestra eficiencia. Además, cuando hay bajo stock nos han ayudado a mantener un mejor control de los materiales críticos. Ha sido una gran inversión para nuestra recicladora y estamos muy satisfechos con los resultados.'
  }

]
