import indexStyle from './HomeCookies.module.scss'
import { useState } from 'react'

export const HomeCookies = () => {
  const [showBanner, setShowBanner] = useState(true)

  const handleAcceptClick = () => {
    closeBanner()
  }

  const handleDeclineClick = () => {
    closeBanner()
  }

  const closeBanner = () => {
    setShowBanner(false)
  }

  return (
    <div className={indexStyle.anuncio}>
      {
          showBanner && (
            <div className={indexStyle.cookie_banner}>
              <p>Utilizamos cookies propias y de terceros para mejorar nuestros servicios. Si continúa con la navegación, consideramos que acepta este uso.</p>
              <div className={indexStyle.button_group}>
                <button className={indexStyle.btncook1} onClick={handleAcceptClick}>Denegar</button>
                <button className={indexStyle.btncook2} onClick={handleDeclineClick}>Aceptar</button>
              </div>
            </div>
          )
        }
    </div>
  )
}
