import Image from 'next/image'
import React from 'react'
import { NavLink } from '@/components/atoms/NavLink/NavLink'

import contact from './Contact.module.scss'

export const Contact = () => {
  return (
    <div className={contact.row}>
      <div className={contact.image_with_title_container}>
        <div className={contact.image_container}>
          <Image
            src='/img/contact/group.svg'
            className={contact.logo1}
            alt='Picture of the author '
            width={745}
            height={696}
          />
        </div>
        <div className={contact.title_container}>
          <h2 className={contact.title1}>Contactanos</h2>
          <p className={contact.text}>Comunícate con nosotros para cualquier duda que tengas sobre nuestros servicios.</p>
          <form className={contact.formcenter}>
            <input className={contact.input1} type='text' placeholder='Nombre' />
            <input className={contact.input1} type='tel' placeholder='Teléfono' />
            <input className={contact.input1} type='email' placeholder='Email' />
            <textarea className={contact.txtarea} rows='4' cols='50' placeholder='Escribe aquí' />
            <div className={contact.borde}>
              <NavLink href='#' type='submit' name='Enviar' className={contact.button1} />
            </div>
          </form>
        </div>

      </div>
    </div>
  )
}
