import { useRouter } from 'next/router'
import styles from '../../organisms/CardMembership/styles.module.scss'
import { Button } from '@/components/atoms'
import { NavLink } from '@/components/atoms/NavLink/NavLink'

export default function CardMembership ({ membership }) {
  const router = useRouter()

  return (
    <article className={styles.card}>
      <div className={styles.card_header}>
        <h2 className={styles.card_title}>{membership.name}</h2>
        {membership.free
          ? (
            <div className={styles.card_pricing}>
              <div>
                <div className={styles.card_price}>
                  <span>{membership.cost}</span>
                  <span className={styles.card_mxn}>
                    <div>/MXN</div>
                  </span>
                </div>
              </div>
            </div>
            )
          : (
            <div className={styles.card_pricing}>
              <div>
                <p className={styles.cardSubtitle}>Desde</p>
                <div className={styles.card_price}>
                  <span>{membership.cost}</span>
                  <span className={styles.card_mxn}>
                    <div>/MXN</div>
                    <div>{membership.validity}</div>
                  </span>
                </div>
              </div>
              <div className={styles.card_info}>
                <p>Pago anual de {membership.promotion_cost}</p>
                <p>&quot;Ahorra {membership.savings_percentage}%&quot;</p>
              </div>
            </div>
            )}
      </div>
      <div className={styles.card_body} dangerouslySetInnerHTML={{ __html: membership.description }} />
      <div className={styles.card_buttons}>
        {
          membership.free
            ? (
              <div>
                <Button
                  label='Gratis'
                  onClick={(e) => { router.push('/register') }}
                  rounded
                  className='btn-yellow btn-xl btn-md-md'
                />
              </div>
              )
            : (
              <div>
                <Button
                  label='Comprar'
                  onClick={(e) => { router.push('/register') }}
                  rounded
                  className='btn-yellow btn-xl btn-md-md'
                />
              </div>
              )
        }
      </div>
      <div className={styles.card_footer}>
        <p>Costo por usuario extra {membership.additional_user_costs} mxn al mes</p>
      </div>

    </article>
  )
}
